body {
  /* background-color: #ffffff !important; */
  font-family: "Chakra Petch", sans-serif;
}
div {
  /* border: 1px solid lightsteelblue; */
}

.app-page {
  height: 100vh;
  /* background-color: #ba1111 !important; */
}

.ad_holder {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 680px;
}
.footer {
  justify-content: flex-end;
}
.link {
  color: #ba1111;
  text-decoration: none;
}
.link:hover {
  color: #ed6868;
  text-decoration: underline;
}
.main_div {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.redo-search-btn {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ba1111;
  border-radius: 7px;
  padding: 3px;
  color: white;
  /* top: 10px;
  left: 0;
  right: 0;
  margin: auto; */
  z-index: 1;
  /* margin-top: 1rem; */
}
.title_font {
  font-family: "Chakra Petch";
}
